import { React, useRef, useEffect, useState } from 'react'
import styles from './commonModel.scss'
import * as THREE from 'three'
import { Space, Tag } from 'antd'
import { nanoid } from 'nanoid'
import { BorderBox13 } from '@jiaminghi/data-view-react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { CSS3DRenderer, CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js';

import { getSearch } from '~/utils/jstools';
import { handlePointerEenter, cloneObj, transLineMode, moduleGrap, findTopParent, moduleColorChange, modelColorRestore } from './modelOpera';
import { getPointList, getAllBlockFunc } from '../../../api/basis-info-controller'
import threeInit from './threeInit'
import { arm, moduleSet, initModule, otherModule, frame, botPositionYMap, moduleFunctionMap1 } from './hardWareConfig';
import { botReset } from './modelOpera';
import '~/index.css'

let MLSELECTED = null
const machine = [initModule]
const editNeedModels = [otherModule]
const allMdels = [arm, moduleSet, frame]
let x, y, z, r, grap, active, mlModule, seal, moduleGroup = {}, cap, frameObj, cubeBot, cubeTop, COLORSOLID, solidModuleGroup = {}, MLStationExport, labelObj, ultrasoundCube, mAObjs = [], mBObjs = [], mCObjs = [], mDObjs = [], mEObjs = [], mC2Objs = []


function CommonModel(props) {
    const webglRef = useRef()
    const labelDiv = useRef()
    const displayPanel = useRef()
    const [moduleName, setModuleName] = useState('模块名称')
    const [moduleFunction, setModuleFunction] = useState('')
    const [hoverModule, setHoverModule] = useState(null)
    const loadedNum = useRef(0)
    const [loadedFlag, setLoadedFlag] = useState(0)
    const functionObj = useRef({})
    const MLStation = useRef()
    useEffect(() => {
        MLStation.current = new threeInit({ ref: webglRef, "labelDiv": labelDiv })
        MLStationExport = MLStation.current
        MLStation.current.initMLCamera()
        MLStation.current.initMLLight()
        MLStation.current.initControls()
        MLStation.current.initRenderer()
        loadAllModal(machine)
        // MLStation.current.newLabel()

        MLStation.current.camera.position.set(0, 40, 100)
        MLStation.current.animate()
        labelObj = MLStation.current.css2DLabel("")
        MLStation.current.scene.add(labelObj)
        MLStation?.current?.addGround('eq01')

    }, [])
    useEffect(() => {
        if (MLStation.current.canvas.current) {
            MLStation.current.onWindowResize()
        }
        if (props.current === 1) {
            const urlParams = new URL(window.location.href);
            let id = getSearch(urlParams.hash).id
            getPointList({ formulaBasisId: id }).then(res => {
                if (res.code === '0') {
                    const { coordinateData } = res.data
                    coordinateData && coordinateData['E1'] && moduleGrap(false, 'E1')
                    coordinateData && coordinateData['E2'] && moduleGrap(false, 'E2')
                }
            })
            //添加长方体
            var geometry = new THREE.BoxGeometry(16, 20, 10);
            var matarial = new THREE.MeshLambertMaterial({ color: 'red' });

            ultrasoundCube = new THREE.Mesh(geometry, matarial);
            transLineMode(ultrasoundCube)
            ultrasoundCube.modType = 'module'
            ultrasoundCube.position.set(32, -20, -38)
            ultrasoundCube.name = "NC"
            moduleGroup['ultrasound'] = ultrasoundCube
            MLStation.current.scene.add(moduleGroup['ultrasound']);
        } else {
            MLStation.current.scene.remove(ultrasoundCube)
        }
    }, [props.current])
    useEffect(() => {
        if (props.current === 0) {
            MLStation.current.camera.position.set(0, 80, 70)

            if (props.removeModule) {
                for (let key in solidModuleGroup) {
                    MLStation.current.scene.remove(solidModuleGroup[key])
                }
                MLStation.current.scene.remove(moduleGroup['wasteLiquid'])
            }
        } else if (props.current === 1) {
            MLStation.current.camera.position.set(0, 80, 70)
            getAllBlockFunc().then(res => {
                const { code, data } = res
                if (code === '0') {
                    const { blockFuncList } = data
                    blockFuncList.forEach(funItem => {
                        let functionStr = '', functionArr = []
                        const { functionList, blockType } = funItem
                        functionList.forEach(item => {
                            const { functionName } = item
                            functionArr.push(functionName.split('-')[0])
                        })
                        functionArr = Array.from(new Set(functionArr))
                        functionArr.forEach((item, index) => {
                            index === 0 ? functionStr += `${item}` : functionStr += `、${item}`
                        })
                        functionObj.current[blockType] = functionStr
                    })
                }
            })
            if (props.removeModule) {
                let modules = [x, y, z, r, grap, mlModule, seal, frameObj]
                modules.forEach(item => MLStation.current.scene.remove(item))
                MLStation.current.scene.remove(cubeTop)
                MLStation.current.scene.remove(cubeBot)
            } else {
                loadAllModal(editNeedModels)
            }
        } else if (props.current === 2) {
            MLStation.current.camera.position.set(-100, 100, 50)
            moduleGrap(false, 'E')
            loadAllModal(allMdels)
            addPlane()
            setHoverModule(false)
            MLStation.current.onWindowResize()
        }
    }, [props.current])
    //清除场景中的所有数据
    useEffect(() => {
        return () => {
            console.log("📕", MLStation?.current?.scene);
            MLStation?.current?.scene?.traverse(obj => {
                // console.log(obj);
                if (obj.type === 'Mesh') {
                    obj.geometry.dispose();
                    obj.material.dispose();
                }
                obj.clear()
            })
            MLStation?.current?.deleteEffect()
            MLStation?.current?.scene?.traverse(obj => {
                // console.log(obj);
            })
            MLStation?.current?.scene?.clear();
            MLStation.current = null
            let clearArrs = [x, y, z, r, grap, active, mlModule, seal, moduleGroup = {}, cap, frameObj, cubeBot, cubeTop, COLORSOLID, solidModuleGroup = {}, MLStation.current, labelObj, ultrasoundCube, mAObjs = [], mBObjs = [], mCObjs = [], mDObjs = [], mEObjs = [], mC2Objs]
            clearArrs.forEach(item => (item = null))
            clearArrs = null

        }
    }, [])
    useEffect(() => {
        let nameMap = { 'U': '超声', 'S': '光谱', 'MS': '质谱', 'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'NC': '超声', 'NWD': '废液槽', 'E1': 'E1', 'E2': 'E2', 'C2': 'C2' }
        if (hoverModule !== null && props.current === 1) {
            hoverModule && setModuleName(`${nameMap[hoverModule]}模块`)
            if (functionObj.current[hoverModule]) {
                setModuleFunction(functionObj.current[hoverModule])
            } else {
                setModuleFunction('')
            }
        } else if (hoverModule !== null && props.current === 0) {
            if (hoverModule === 'D' || hoverModule === 'E') {
                setHoverModule(null)
            } else {
                setModuleName(`${hoverModule}模块`)
                setModuleFunction(moduleFunctionMap1[hoverModule])
            }
        }
    }, [hoverModule])
    useEffect(() => {
        // console.log(loadedFlag, loadedNum.current);
        if (loadedNum.current === 19) {
            props.initModelLoaded()
        }
    }, [loadedFlag])

    const modelInit = (index, object, path, position, name, type, parent) => {
        // getFrame.then((object) => {
        parent === undefined || (object.module = path)
        object.number = index
        object.traverse((child) => {
            child.castShadow = true;
            child.name = name
            child['modType'] = type
            if (type === 'bot') {
                child['selected'] = false
                child.number = index
                if (child.geometry) {
                    child.material.emissive.set(0xC0C0C0)
                }
            }
        })
        object.add(MLStation.current.css2DLabel(`${object.number}号瓶子`));
        let box = new THREE.Box3().setFromObject(object); // 获取模型的包围盒
        let mdlen = box.max.x - box.min.x; // 模型长度
        let mdwid = box.max.z - box.min.z; // 模型宽度
        let mdhei = box.max.y - box.min.y; // 模型高度
        let x1 = box.min.x + mdlen / 2; // 模型中心点坐标X
        let y1 = box.min.y + mdhei / 2; // 模型中心点坐标Y
        let z1 = box.min.z + mdwid / 2; // 模型中心点坐标Z
        object.position.set(-x1 + position[0], -y1 + position[1], -z1 + position[2]); // 将模型进行偏移

        switch (path) {
            case 'wasteLiquid': {
                // wasteLiquid = object
                moduleGroup['wasteLiquid'] = object
                // transLineMode(object)
                break;
            }
            case 'frame':
                {
                    frameObj = object
                    // transLineMode(object)

                    break;
                    // let geoLine = new THREE.geometry()
                }
            case 'shake':
                {
                    // transLineMode(object)
                    moduleGroup["A"] = object
                    loadGLTFMod("srbot").then((srBot) => {
                        for (let j = 0; j < 8; j++) {
                            for (let i = 0; i < 3; i++) {
                                let srBotOne = cloneObj(srBot.scene.children[1])
                                modelInit(j * 3 + i + 1, srBotOne, 'srBot', [-4 + i * 4, 6, 14 - j * 4], `A-${i + 1}-${j + 1}`, 'bot', object)
                            }
                        }
                        loadedNum.current += 1
                        setLoadedFlag(loadedNum.current)

                    })
                    break;
                }
            case 'shake2':
                {
                    // transLineMode(object)
                    moduleGroup["B"] = object

                    loadGLTFMod('srbot').then((srBot) => {
                        for (let j = 0; j < 8; j++) {
                            for (let i = 0; i < 3; i++) {
                                modelInit(j * 3 + i + 1, cloneObj(srBot.scene.children[1]), 'srBot2', [-4 + i * 4, 6, 14 - j * 4], `B-${i + 1}-${j + 1}`, 'bot', object)
                            }
                        }
                        loadedNum.current += 1
                        setLoadedFlag(loadedNum.current)
                    })
                    break;

                }
            case 'lStore2':
                {
                    // transLineMode(object)
                    object.rotateY(3 * Math.PI / 2)
                    // object.rotateY(Math.PI / 2)

                    moduleGroup["C2"] = object
                    loadGLTFMod('100ml').then((bot100ml) => {
                        // originColor = bot100ml.material.color.getHex();
                        for (let j = 0; j < 2; j++) {
                            for (let i = 0; i < 6; i++) {
                                modelInit(j * 6 + i + 1, cloneObj(bot100ml.scene.children[1]), '100ml2', [- j * 5.5 + 2.5, 4, 14 - i * 5.5], `C2-${i + 1}-${j + 1}`, 'bot', object)
                            }
                        }
                        loadedNum.current += 1
                        setLoadedFlag(loadedNum.current)
                    })
                    break;

                }
            case 'lStore':
                {
                    // transLineMode(object)
                    moduleGroup["C"] = object
                    loadGLTFMod('100ml').then((bot100ml) => {
                        for (let j = 0; j < 6; j++) {
                            for (let i = 0; i < 2; i++) {
                                modelInit(j * 2 + i + 1, cloneObj(bot100ml.scene.children[1]), '100ml', [-3.5 + i * 5.5, 4, 14 - j * 5.5], `C-${i + 1}-${j + 1}`, 'bot', object)
                            }
                        }
                        loadedNum.current += 1
                        setLoadedFlag(loadedNum.current)
                    })
                    break;

                }
            case 'seSupport':
                {
                    // transLineMode(object)
                    moduleGroup["E"] = object

                    loadGLTFMod('extBot').then((extBot) => {
                        for (let j = 0; j < 5; j++) {
                            for (let i = 0; i < 3; i++) {
                                modelInit(j * 3 + i + 1, cloneObj(extBot.scene.children[1]), 'extBot', [-7 + i * 3, 6, 14 - j * 6], `E-${i + 1}-${j + 1}`, 'bot', object)

                            }
                        }

                        loadedNum.current += 1
                        setLoadedFlag(loadedNum.current)


                    })
                    break;

                }
            case 'SPESupport':
                {
                    // transLineMode(object)

                    moduleGroup["D"] = object

                    loadGLTFMod('reBot').then((reBot) => {
                        for (let j = 0; j < 10; j++) {
                            for (let i = 0; i < 3; i++) {
                                let rebotClone = cloneObj(reBot.scene.children[1])
                                modelInit(j * 3 + i + 1, rebotClone, 'reBot', [-3 + i * 3, 6, 13 - j * 3], `D-${i + 1}-${j + 1}`, 'bot', object)
                            }
                        }
                        loadedNum.current += 1
                        setLoadedFlag(loadedNum.current)


                    })
                    break;

                }

            case 'x': {
                x = object
                break;
            }
            case 'y': {
                y = object
                break;
            }
            case 'z': {
                object.children[1].scale.y = 48
                object.children[1].position.y = -380
                z = object
                break;
            }
            case 'r': {
                r = object
                break;
            }
            case 'grap': {

                grap = object
                break;
            }

            case '100ml': {

                object.traverse(item => item.material && (COLORSOLID = item.material.color.getHex()))
                mCObjs.push(object)
                break;
            }
            case 'srBot': {

                object.traverse(item => item.material && (COLORSOLID = item.material.color.getHex()))
                mAObjs.push(object)
                break;
            }
            case 'srBot2': {

                object.traverse(item => item.material && (COLORSOLID = item.material.color.getHex()))
                mBObjs.push(object)
                break;
            }
            case '100ml2': {

                object.traverse(item => item.material && (COLORSOLID = item.material.color.getHex()))
                mC2Objs.push(object)
                break;
            }
            case 'reBot': {
                mDObjs.push(object)
                break;
            }
            case 'extBot': {
                mEObjs.push(object)
                break;
            }
            case 'mlModule': {
                mlModule = object
                break;
            }
            case 'seal': {
                seal = object
                break;
            }
            // case 'srBot': {
            //     if (object.module === 'srBot') {
            //         mAObjs.push(object)
            //     } else {
            //         mBObjs.push(object)
            //     }

            //     break
            // }
            case 'sixWay': {
                solidModuleGroup["sixWay"] = object
                // sixWay = object
                object.rotateX(Math.PI)
                break;
            }
            case 'lcms': {
                solidModuleGroup["lcms"] = object
                // lcms = object
                object.rotateX(3 * Math.PI / 2)
                break;
            }
            case 'roman': {
                solidModuleGroup["roman"] = object
                // roman = object
                object.rotateX(Math.PI)
                object.rotateZ(Math.PI)
                break;
            }
            default:
                break;
        }
        parent === undefined ? MLStation.current.scene.add(object) : parent.add(object)
        // })
    }
    const addPlane = () => {
        let planeMaterial = new THREE.MeshPhongMaterial({
            color: 0x004080,
            side: THREE.DoubleSide,
            shininess: 100,
            transparent: true,
            opacity: 0.3,
            depthWrite: false
        })
        let cubeGeometryTop = new THREE.BoxGeometry(100, 70, 1)
        let cubeGeometryBot = new THREE.BoxGeometry(100, 70, 1)


        cubeTop = new THREE.Mesh(cubeGeometryTop, planeMaterial)
        cubeBot = new THREE.Mesh(cubeGeometryBot, planeMaterial)
        cubeTop.position.set(0, 65, 43)
        cubeTop.rotation.x -= Math.PI * 0.5;
        cubeTop.position.y -= 1.5;
        cubeTop.rotateX(Math.PI / 2)
        cubeBot.position.set(0, 5, 43)
        cubeBot.rotation.x -= Math.PI * 0.5;
        cubeBot.position.y -= 1.5;
        cubeBot.rotateX(Math.PI / 2)
        MLStation.current.scene.add(cubeTop)
        MLStation.current.scene.add(cubeBot)

    }

    const loadAllModal = (loadArr) => {
        loadArr.forEach((item) => {
            item.forEach((modItem, index) => {
                if (modItem[0] !== 'shake2') {
                    modItem[0] === 'lStore2' ? loadGLTFMod('lStore').then((obj) => {
                        modelInit(index, obj.scene.children[1], ...modItem)
                    }) : loadGLTFMod(modItem[0]).then((obj) => {

                        if (modItem[0] === 'wasteLiquid' || modItem[0] == 'frame') {
                            modelInit(index, obj.scene.children[0], ...modItem)
                        } else if (modItem[0] === 'roman' || modItem[0] === 'sixWay' || modItem[0] === 'lcms') {
                            modelInit(index, obj.scene, ...modItem)
                        } else {
                            modelInit(index, obj.scene.children[1], ...modItem)
                        }
                    })
                } else {
                    modItem[0] === 'shake2' && loadGLTFMod('shake').then((obj) => {
                        modelInit(index, obj.scene.children[1], ...modItem)
                    })


                }

            })
        })
        loadGLTFMod("cover").then((object) => {
            cap = object
        })
    }
    const loadGLTFMod = (path) => {
        const manager = new THREE.LoadingManager();
        let gltfLoader = new GLTFLoader(manager);
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/hts/gltf/"); // 设置public下的解码路径，注意最后面的/
        dracoLoader.setDecoderConfig({ type: "js" });
        dracoLoader.preload();
        gltfLoader.setDRACOLoader(dracoLoader);
        manager.onLoad = () => {
            // setLoadedNum(loadedNum + 1)
            loadedNum.current += 1
        };
        const getFrame = new Promise((resolve, reject) => {
            gltfLoader
                .loadAsync(`https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/hts/glb/${path}.glb`)
                .then((objGroup) => {
                    resolve(objGroup);
                })
                .catch((err) => {
                    reject(err);
                });
        })
        return getFrame

    }
    const handlePointerClick = (e) => {
        const selectedObj = MLStation.current.onPointerClick(e, 'ML', MLStation.current.canvas.current)
        if (selectedObj) {
            const { parent } = selectedObj
            botReset({ color: 'back', labelVisiable: 0 })

            let clickObj = { type: '', moduleName: '', col: '', row: '', number: '' }
            try {
                switch (selectedObj.modType) {
                    case 'bot':
                        {
                            clickObj.type = 'bot'
                            // if (MLSELECTED) {
                            //     MLSELECTED.material.color.setHex(MLSELECTED.currentHex)
                            //     MLSELECTED.parent.position.y = botPositionYMap[MLSELECTED.name[0]]
                            //     MLSELECTED.parent.children[1].layers.set(1)
                            // }
                            // if (selectedObj.selected === false) {
                            //     MLSELECTED = selectedObj;
                            //     MLSELECTED.currentHex = MLSELECTED.material.color.getHex();
                            //     parent.position.y = botPositionYMap[MLSELECTED.name[0]] + 5
                            //     parent.children[1].layers.set(0)
                            //     selectedObj.traverse((child) => {
                            //         child.geometry && child.material.color.set(0xff0000)
                            //     })
                            selectedObj.parent.children[1].layers.mask = 1
                            selectedObj.selected = true
                            const clickArr = selectedObj.name.split('-')
                            clickObj.moduleName = clickArr[0]
                            clickObj.col = clickArr[1]
                            clickObj.row = clickArr[2]
                            clickObj.number = selectedObj.number
                            // } else {
                            //     MLSELECTED = null
                            //     selectedObj.selected = false
                            // }
                            break;
                        }

                    case 'module':
                        {
                            clickObj.type = 'module'
                            clickObj.moduleName = selectedObj.name

                            if (props.current === 1) {
                                for (let key in moduleGroup) {
                                    if (key !== selectedObj.name && moduleGroup[key]) {
                                        moduleGroup[key].click = false
                                        moduleGroup[key].traverse(
                                            child => {
                                                if (child.geometry && child.modType === 'module') {
                                                    child.material.color.setHex(COLORSOLID)
                                                }
                                            }
                                        )
                                    }
                                }
                                for (let key in solidModuleGroup) {
                                    if (key !== selectedObj.name) {

                                        solidModuleGroup[key].click = false
                                        solidModuleGroup[key].traverse(
                                            // eslint-disable-next-line no-loop-func
                                            child => {
                                                if (child.geometry && child.modType === 'module') {
                                                    child.material.color.setHex(COLORSOLID)
                                                }
                                            }
                                        )
                                    }
                                }
                                // modelColorRestore()
                                selectedObj.click = true
                                let bigParent = findTopParent(selectedObj)
                                bigParent.traverse(child => child.click = true)
                                let changeObj = findTopParent(selectedObj)
                                changeObj.traverse((child) => {
                                    if (child.geometry && child.modType === 'module') {
                                        child.material.color.set(0xff0000)
                                    }
                                })
                            }
                            break;
                        }
                    default:
                        clickObj.type = null

                        break;
                }
            } catch (error) {
                console.log(error);
            }
            props.handleModelClick(clickObj)
        } else {
            for (let key in moduleGroup) {
                moduleGroup[key].click = false
                // moduleColorChange(moduleGroup[key], 0x00BFFF)
            }
            modelColorRestore()
        }
    }

    const onHandelMouseMove = (e) => {
        loadedNum.current >= 19 && handlePointerEenter(props.current, e, MLStation.current, setHoverModule)
    }
    return (
        <div className={`${styles.main} modelBg`}>
            <div ref={webglRef} className={`${props.current === 2 ? styles.simulate : styles.conditionInit}`} onClick={e => handlePointerClick(e)} onMouseMove={e => onHandelMouseMove(e)}></div>
            <div className={styles.labelDiv} ref={labelDiv}>
            </div>
            <div style={{ visibility: hoverModule ? 'visible' : 'hidden' }} className={styles.displayPanel} ref={displayPanel} >
                <BorderBox13>
                    <div className={styles.panelContent}>
                        <p className={styles.title}>模块名称：</p>
                        <p className={styles.moduleName}>
                            {moduleName}
                        </p>
                        <p className={styles.title}>可执行操作：</p>
                        <Space size={[0, 8]} wrap className={styles.moduleFun}>
                            {moduleFunction && moduleFunction.split('、').map(item =>
                                <Tag color="blue" key={nanoid()}>{item}</Tag>
                            )}
                        </Space>
                    </div>
                </BorderBox13>
            </div>
        </div>
    )
}




export { CommonModel, x, y, z, r, grap, active, mlModule, seal, moduleGroup, cap, mAObjs, mBObjs, mCObjs, mC2Objs, mDObjs, mEObjs, MLStationExport, COLORSOLID, solidModuleGroup, cubeBot, cubeTop, frameObj }