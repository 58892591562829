import { useState, useEffect } from "react";
import { store } from "~/store";
import routers from "./router/index";
import { Spin, message } from "antd";
import i18n from "i18next";
import {
  NavLink,
  useRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Author from "./router/author";
import TourGuide from "~/components/guide";
import { isMobile } from "./utils/jstools";
import "./App.scss";
import AliAvatar from "~/components/aliAvatar";

import Vconsole from "vconsole"; // 引入vconsole插件

export default function App() {
  const GetRoutes = () => useRoutes(routers); //一定要是函数内,路由表这根步注册一次就行 子路由无需注册
  const location = useLocation();
  const navigate = useNavigate();

  // 全局loading
  const [isLoading, setIsLoading] = useState(false);
  const [guideInfoObj, setGuideInfoObj] = useState(false);
  // 监控dispatch提交的action改变
  store.subscribe(() => {
    const { settings, guideInfo, modelLoad } = store.getState();
    // console.log("🚀 ~ file: App.jsx:19 ~ store.subscribe ~ settings:", guideInfo)
    if (modelLoad.loading || settings.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    // 移动端不展示引导
    if (!isMobile()) {
      setGuideInfoObj(guideInfo);
    }
  });

  useEffect(() => {
    // 调用函数
    detectLandscapeMode();

    // 初始化vconsole
    // const vConsole = new Vconsole();
  }, []);

  return (
    <>
      <Author>{GetRoutes()}</Author>
      <Spin
        spinning={isLoading}
        delay={0}
        size="large"
        wrapperClassName="rootSpin"
        className="rootSpin"
        style={{ zIndex: isLoading ? "10000" : "-1" }}
      ></Spin>
      {/* 全局引导组件 */}
      <TourGuide options={guideInfoObj} />
      <AliAvatar />
    </>
  );

  function detectLandscapeMode() {
    let isLandscape = false;

    // 初始检测是否为横屏
    if (window.orientation === 90 || window.orientation === -90) {
      isLandscape = true;
    }
    let cacheWidth = 0;
    // 监听 orientationchange 事件
    window.addEventListener("orientationchange", function (e) {
      const screenWidth = e.target.innerWidth;

      const maxMobileWidth = 768; // 根据需求调整这个阈值
      let isMobile = cacheWidth <= maxMobileWidth;
      // console.log("🚀 ~ isMobile:", e, isMobile, screenWidth)

      // 根据 window.orientation 判断是否为横屏
      if (
        isMobile &&
        (window.orientation === 90 || window.orientation === -90)
      ) {
        isLandscape = true;
        message.warning("横屏后，手机端部分功能体验受影响！");
      } else {
        isLandscape = false;
      }
      cacheWidth = screenWidth;
    });
  }
}
