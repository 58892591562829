import request from "./modules/request.js";

// 接口格式规范 - 技术经理负责检查下列接口是否按照规范实施
// （get请求格式）
// export function nonce(params) {
//   return request({
//     url: '/public/login/digest/nonce.do',
//     method: 'get'
//   })
//   params:params,
// }

// （post请求格式）
// export function nonce(data) {
//   return request({
//     url: '/public/login/digest/nonce.do',
//     method: 'post',
// data:data,
//   })
// }

// 获取任务列表
export function getProcessBasisConfigList(data) {
  return request({
    url: "/common/basis-config/getProcessBasisConfigList",
    method: "post",
    data: data,
  });
}

// 分页获取关联文件
export function getResultFile(data) {
  return request({
    url: "/common/result-file-common/selectSyntheticResultFilePage",
    method: "post",
    data: data,
  });
}

// 解绑文件
export function untieFile(data) {
  return request({
    url: "/common/result-file-common/unbindFile",
    method: "post",
    data: data,
  });
}

// 绑定文件
export function bindingFile(data) {
  return request({
    url: "/common/result-file-common/bindFile",
    method: "post",
    data: data,
  });
}

// 绑定文件
export function downloadResult(data) {
  return request({
    url: "/common/result-file-common/exportResultFile",
    method: "post",
    responseType: "blob",
    params: data,
  });
}

// 批量下载记录
export function exportResultFiles(params) {
  return request({
    url: "/common/result-file-common/exportResultFiles",
    method: "post",
    responseType: "blob",
    params,
  });
}

// 查药品详情
export function getAllDrugList() {
  return request({
    url: "/common/basis-drug/queryAll",
    method: "post",
  });
}

// 根据模块和功能编号获取可执行动作接口（高通量）
export function functionList(data) {
  return request({
    url: "/block/basis-block-info/functionList",
    method: "post",
    data,
  });
}

// 根据模块和功能编号获取可执行动作接口（通用）
export function functionListCom(data) {
  return request({
    url: "/formula/basis-block/functionList",
    method: "post",
    data,
  });
}
// 抓手状态列表
export function getList(data) {
  return request({
    url: "/common/data-dictionary/getList",
    method: "post",
    data,
  });
}

// 根据模块和功能编号获取可执行动作接口
export function commonFunctionList(data) {
  return request({
    url: "/block/basis-block-info/commonFunctionList",
    method: "post",
    data,
  });
}
// 根据模块和功能编号获取可执行动作接口(水热合成)
export function commonFunctionListHT(data) {
  return request({
    url: "/formula/basis-block/commonFunctionList",
    method: "post",
    data,
  });
}

// 获取区块类型列表
export function blockList(data) {
  return request({
    url: "/formula/basis-info/blockList",
    method: "post",
    params: data,
  });
}
export function getAllDrugListPage(data) {
  return request({
    url: "/common/basis-drug/queryPage",
    method: "post",
    data,
  });
}

export function login(data) {
  return request({
    url: "/common/client-user/clientLogin",
    method: "post",
    data,
  });
}

export function getFunctionMap(data) {
  return request({
    url: "/common/basis-config/getFunctionMap",
    method: "post",
    data: data,
  });
}

export function getFunctionList(data) {
  return request({
    url: "/formula/basis-block/selectAllBlockFunctionMap",
    method: "post",
    data: data,
  });
}

export function deviceList(data) {
  return request({
    url: "/common/basis-device-info/selectList",
    method: "post",
    data: data,
  });
}

// 跳过引导页
export function skipGuideApi(data) {
  return request({
    url: "/common/client-user/skipGuideSwitch",
    method: "post",
    params: data,
  });
}

export function getPushedLog(data) {
  return request({
    url: "/common/basis-experiment-log/getExperimentLog",
    method: "post",
    data: data,
  });
}

export function getSyntheticInfo(data) {
  return request({
    url: "/formula/synthetic-result/getSyntheticInfo",
    method: "post",
    data: data,
  });
}

// 用户退出
export function loginOut(data) {
  return request({
    url: "/common/client-user/loginOut",
    method: "post",
    data: data,
  });
}

// 配方新增
export function drugAdd(data) {
  //console.log(params);
  return request({
    url: "/common/basis-drug/add",
    isUploadFile: true,
    method: "post",
    //params: params,
    data: data,
  });
}
// 编辑药品
export function drugEdit(data) {
  //console.log(params);
  return request({
    url: "/common/basis-drug/edit",
    isUploadFile: true,
    method: "post",
    //params: params,
    data: data,
  });
}

// 配方列表查询
export function drugList(data) {
  return request({
    url: "/common/basis-drug/queryPage",
    method: "post",
    data: data,
  });
}

// 配方列表删除
export function drugDel(data) {
  return request({
    url: "/common/basis-drug/delete",
    method: "post",
    data: data,
  });
}

// 更新密码
export function updatePassword(data) {
  return request({
    url: "/common/client-user/updatePassword",
    method: "post",
    data: data,
  });
}

// 获取系统基础信息
export function getSysBasisData(data) {
  return request({
    url: "/common/data-dictionary/getSysBasisData",
    method: "get",
    data: data,
  });
}

// 发送验证码
export function sendVerifyCode(data) {
  return request({
    url: "/common/client-user/sendVerifyCode",
    method: "get",
    params: data,
  });
}
// 绑定手机号
export function bindPhone(data) {
  return request({
    url: "/common/client-user/bindPhone",
    method: "get",
    params: data,
  });
}

// 获取用户列表
export function getUserList(data) {
  return request({
    url: "/common/client-user/getUserList",
    method: "post",
    data: data,
  });
}

// 新增更新用户
export function saveUserOrUpdate(data) {
  return request({
    url: "/common/client-user/saveOrUpdate",
    method: "post",
    data: data,
  });
}

// 新增更新用户
export function updateUserStatus(data) {
  return request({
    url: "/common/client-user/updateStatus",
    method: "get",
    params: data,
  });
}

// 新增更新用户
export function getDictList(data) {
  return request({
    url: "/common/data-dictionary/getList",
    method: "POST",
    data: data,
  });
}

//注册手机验证码
export function sendRegisterPhoneMsg(data) {
  return request({
    url: "/common/client-user/sendRegisterPhoneMsg",
    method: "POST",
    params: data,
  });
}
//用户注册
export function registerUser(data) {
  return request({
    url: "/common/client-user/registerUser",
    method: "POST",
    data: data,
  });
}
//登录手机验证码
export function sendLoginPhoneMsg(data) {
  return request({
    url: "/common/client-user/sendLoginPhoneMsg",
    method: "POST",
    params: data,
  });
}
//手机号登录
export function clientLoginByPhone(data) {
  return request({
    url: "/common/client-user/clientLoginByPhone",
    method: "POST",
    data: data,
  });
}
//修改个人信息
export function updateUserInfo(data) {
  return request({
    url: "/common/client-user/updateUserInfo",
    method: "POST",
    data: data,
  });
}
//获取用户信息

export function queryUserInfo(data) {
  return request({
    url: "/common/client-user/queryUserInfo",
    method: "POST",
    params: data,
  });
}
//获取图形验证码
export function captcha(data) {
  return request({
    url: "/common/client-user/images/captcha",
    method: "get",
    params: data,
    responseType: "blob",
  });
}

//监控视频列表
export function getVideoFile(data) {
  return request({
    url: "/formula/video-file/getVideoFile",
    method: "get",
    params: data,
  });
}

export function startInstance(data) {
  return request({
    url: "/avatar/startInstance",
    method: "POST",
    params: data,
  });
}

// 发送文本
export function sendText(data) {
  return request({
    url: "/avatar/sendText",
    method: "POST",
    params: data,
  });
}

// 停止实例
export function stopInstance(data) {
  console.log("🚀 ~ stopInstance ~ data:", data);
  return request({
    url: "/avatar/stopInstance",
    method: "POST",
    params: data,
  });
}
