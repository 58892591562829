
import React, { lazy, } from "react";
import { nanoid } from 'nanoid'

const Material = lazy(() => import('~/views/deviceAdmin/material'))
const User = lazy(() => import('~/views/deviceAdmin/user'))

const adminRoutes = [
    // 业务路由
    {
        path: '/deviceAdmin/material', key: nanoid(), element: <Material />, name: '原料库',
    },
    {
        path: '/deviceAdmin/user', key: nanoid(), element: <User />, name: '用户管理',
    },
]

export default adminRoutes 