import React, { Suspense, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { ConfigProvider, message } from "antd";
import App from './App'
import { HashRouter } from 'react-router-dom';

import i18n from 'i18next';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';

// import store from "./store/index";
import { store } from "~/store";

// import 'default-passive-events'

import SkeletonDom from '~/components/skeleton';

// 全局字体图标
import "~/assets/iconfont/iconfont.css"
// 全局样式
import '~/assets/style/global.scss'
import './utils/i18n'; // 确保先导入i18n配置
// 全局的弹框配置
message.config({
  top: 50,
  duration: 5,
  maxCount: 5,
});
const root = createRoot(document.getElementById("root"));



const RenderDom = () => {
  const [language, setLanguage] = useState('en');
  store.subscribe(() => {
    const { settings } = store.getState();
    setLanguage(settings.language)
    // console.log('settings', settings)
  });

  return (
    <HashRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#1677ff",
          },
        }}
        locale={language == 'zh-CN' ? zhCN : enUS}
      >

        <Suspense fallback={<SkeletonDom />}>
          <App />
        </Suspense>
      </ConfigProvider>

    </HashRouter>
  )
}

// 插入的模版，目标位置
root.render(
  <RenderDom />
)
