
import React, { lazy, } from "react";
import { nanoid } from 'nanoid'

// 硬件测试
import adminRoutes from './deviceAdmin'


// 布局组件
const HomeLayout = lazy(() => import('~/layout/home'))

// 首页
// import Home from '~/views/home/index.jsx'
const HomeH5 = lazy(() => import('~/views-h5/home/index'))

const FormulaListH5 = lazy(() => import('~/views/formulaList'))

// 高通量
const HTConfigure = lazy(() => import('~/views/devices/HT/configure'))
const HTController = lazy(() => import('~/views/devices/HT/controller'))

const MultFlow = [
    // 业务路由
    {
        path: '/home-h5', key: nanoid(), element: <HomeLayout />,

        children: [
            {
                path: '',
                key: nanoid(),
                element: <HomeH5 />,
                name: '首页',
            },
            // {
            //     path: '/formulaList',
            //     key: nanoid(),
            //     element: <FormulaListH5 />,
            //     name: '配方列表'
            // },
            // ...adminRoutes
        ],
    },

    // {
    //     path: '/formulaList/HTConfigure', key: nanoid(), element: <HTConfigure />, name: '流程配置(木棉FS01）',
    // },

    // {
    //     path: '/formulaList/HTController', key: nanoid(), element: <HTController />, name: '配方合成(木棉FS01）',
    // },

]

export default MultFlow 