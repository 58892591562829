// 引入创建容器方法
import { createStore } from "redux";
import { settings } from "./modules/settings"
import { userInfo } from "./modules/userInfo"
import { workFlow } from "./modules/workFlow"
import { guideInfo } from "./modules/guide"
import { modelLoad } from "./modules/modelLoad";
// 初始化状态
const initState = {
    settings,
    userInfo,
    workFlow,
    guideInfo,
    modelLoad
};

// reducer处理action逻辑
const reducer = (state = initState, action) => {
    // console.log('reducer:', state, action)
    // 每次提交改变返回的都是一个新状态
    let newState = { ...state };
    // console.log('newState[action.type]:', action)
    switch (action.type) {
        case "settings":
            newState[action.type] = { ...newState[action.type], ...action };

            return newState;
        case "userInfo":
            newState[action.type] = { ...newState[action.type], ...action };
            delete newState.type

            return newState;
        case "workFlow":
            newState[action.type] = { ...newState[action.type], ...action };
            return newState;
        case "guideInfo":
            newState[action.type] = { ...newState[action.type], ...action };
            return newState;
        case "modelLoad":
            newState[action.type] = { ...newState[action.type], ...action };

            return newState;
        default:
            return state;

    }
};

// 创建一个store容器
export const store = createStore(reducer);


// console.log('ssss', store.getState())
