


import React, { useEffect, useState } from "react";
import {
    Button,
    Drawer,
    Tour,
    message,
} from 'antd';
import { store } from '~/store'

import { skipGuideApi } from '~/api/common.js'

import { home, createFormula, summaryInfo, uploadFormula, addFormula } from './config/common'
import { workFlowModel, workFlowStep1Temp, workFlowStep1Mater, workFlowStep2Model } from './config/XFZ'
import { modelInfoSR, createGroupSR, ZZTC3, ZZTC4, ZZTC5, ZZTC6, ZZTC7, ZZTC8, ZZTC9, ZZTC10, ZZTC11, ZZTC12, ZZTC13, ZZTC14, ZZTC15, ZZTC16, ZZTC17, ZZTC18, ZZTC19, ZZTC20, ZZTC21, ZZTC22, ZZTC23 } from './config/SR'

import { ZZT2, ZZT3, ZZT4, ZZT5, ZZT6, ZZT7, ZZT8, ZZT9, ZZT10, ZZT11, ZZT12, ZZT13, ZZT14, ZZT15, ZZT16, ZZT17, ZZT18, ZZT19, ZZT20, ZZT21, ZZT22, ZZT23 } from './config/LXL'
import { PC,PC1,PC2,PC3,PC4,PC5,PC6,PC7,PC8,PC9,PC10,PC11,PC12,PC13,PC14,PC15,PC16,PC17,PC18,PC19,PC20,PC21} from './config/PC'

import { HFCR,HFCR1,HFCR2,HFCR3,HFCR4,HFCR5,HFCR6,HFCR7,HFCR8,HFCR9,HFCR10,HFCR11,HFCR12,HFCR13,HFCR14,HFCR15,HFCR16,HFCR17,HFCR18,HFCR19,HFCR20,HFCR21,HFCR22 } from './config/HFCR'
import { setColor as setColor02 } from "~/components/equipment02/modules/modelOpera";
import { setColor as setColor02B } from "~/components/equipment02B/modules/modelOpera";
import { setColor as setColorPC } from "~/components/photocatalysis/modules/modelOpera";
import { setColor as setColor1 } from "~/components/process/commonModel/modelOpera";
import { setColor as setColorHFCR } from "~/components/modelHFCR/modules/modelOpera";
export default function TourGuide({ options: { el, guideIsShow, current, page } }) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    // console.log("🚀 ~ file: index.jsx:23 ~ TourGuide ~ userInfo:", userInfo)
    const flowInfo = JSON.parse(sessionStorage.getItem('flowInfo') || '{}')

    const [steps, setSteps] = useState([]);
    const [tourOpen, setTourOpen] = useState(false);
    const [curStep, setCurStep] = useState(current);
    const [preStep, setPreStep] = useState(current);

    // 当前步骤控制器
    const tourStepChange = (cur) => {
        //console.log(page)
        // console.log(cur)
        // console.log("🚀 ~ file: index.jsx:32 ~ tourStepChange ~ cur:", preStep)

        // 回复上一个模型颜色
        if (preStep && preStep?.blockName || cur == 0) {
            if (steps[cur]?.blockType == 'xfz') {
                setColor1(preStep?.blockName, 0, 0, '#00BFFF')
            } else {
                if (page == 'ZZT2') {
                    setColor02B({ equipmentType:flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
                } else if(page == 'modelInfoSR') {
                    setColor02({ equipmentType: flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
                } else if(page == 'PC'){
                    setColorPC({ equipmentType: flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
                } else if(page=='HFCR'){
                    setColorHFCR({ equipmentType: flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
                }

            }
        }
        // 渲染当前模型颜色
        if (steps[cur]?.blockName) {
            if (steps[cur]?.blockType == 'xfz') {
                setColor1(steps[cur]?.blockName, 0, 0, 'rgba(255,0,0)')
            } else {
                if (page == 'ZZT2') {
                    setColor02B({ equipmentType: flowInfo.equipmentType, module: steps[cur]?.blockName, color: '#ff9595' })
                } else if(page == 'modelInfoSR') {
                    setColor02({ equipmentType: flowInfo.equipmentType, module: steps[cur]?.blockName, color: '#ff9595' })
                } else if(page == 'PC'){
                    setColorPC({ equipmentType: flowInfo.equipmentType, module: steps[cur]?.blockName, color: '#ff9595' })
                }else if(page == 'HFCR'){
                    setColorHFCR({ equipmentType: flowInfo.equipmentType, module: steps[cur]?.blockName, color: '#ff9595' })
                }
            }
            setPreStep(steps[cur])
        }
        setCurStep(cur)
    }


    // 初始化
    useEffect(() => {
        // console.log("🚀 ~ file: index.jsx:12 ~ TourGuide ~  page:", page)
        // console.log("🚀 ~ file: index.jsx:12 ~ TourGuide ~  el:", el)
        // console.log("🚀 ~ file: index.jsx:12 ~ TourGuide ~ guideIsShow:", guideIsShow,)
        // console.log("🚀 ~ file: index.jsx:12 ~ TourGuide ~  current:", current)

        let steps = []
        switch (page) {
            case 'home':
                steps = home(el)
                break;
            case 'uploadFormula':
                steps = uploadFormula(el)
                break;
            case 'createFoumula':
                steps = createFormula(el)
                break;
            case 'addFormula':
                steps = addFormula(el)
                break;
            case 'summaryInfo':
                steps = summaryInfo(el)
                break;
            case 'ZZT2':
                steps = ZZT2(el)
                break;
            case 'ZZT3':
                steps = ZZT3(el)
                break;
            case 'ZZT4':
                steps = ZZT4(el)
                break;
            case 'ZZT5':
                steps = ZZT5(el)
                break;
            case 'ZZT6':
                steps = ZZT6(el)
                break;
            case 'ZZT7':
                steps = ZZT7(el)
                break;
            case 'ZZT8':
                steps = ZZT8(el)
                break;
            case 'ZZT9':
                steps = ZZT9(el)
                break;
            case 'ZZT10':
                steps = ZZT10(el)
                break;
            case 'ZZT11':
                steps = ZZT11(el)
                break;
            case 'ZZT12':
                steps = ZZT12(el)
                break;
            case 'ZZT13':
                steps = ZZT13(el)
                break;
            case 'ZZT14':
                steps = ZZT14(el)
                break;
            case 'ZZT15':
                steps = ZZT15(el)
                break;
            case 'ZZT16':
                steps = ZZT16(el)
                break;
            case 'ZZT17':
                steps = ZZT17(el)
                break;
            case 'ZZT18':
                steps = ZZT18(el)
                break;
            case 'ZZT19':
                steps = ZZT19(el)
                break;
            case 'ZZT20':
                steps = ZZT20(el)
                break;
            case 'ZZT21':
                steps = ZZT21(el)
                break;
            case 'ZZT22':
                steps = ZZT22(el)
                break;
            case 'ZZT23':
                steps = ZZT23(el)
                break;
            case 'ZZTC3':
                steps = ZZTC3(el)
                break;
            case 'ZZTC4':
                steps = ZZTC4(el)
                break;
            case 'ZZTC5':
                steps = ZZTC5(el)
                break;
            case 'ZZTC6':
                steps = ZZTC6(el)
                break;
            case 'ZZTC7':
                steps = ZZTC7(el)
                break;
            case 'ZZTC8':
                steps = ZZTC8(el)
                break;
            case 'ZZTC9':
                steps = ZZTC9(el)
                break;
            case 'ZZTC10':
                steps = ZZTC10(el)
                break;
            case 'ZZTC11':
                steps = ZZTC11(el)
                break;
            case 'ZZTC12':
                steps = ZZTC12(el)
                break;
            case 'ZZTC13':
                steps = ZZTC13(el)
                break;
            case 'ZZTC14':
                steps = ZZTC14(el)
                break;
            case 'ZZTC15':
                steps = ZZTC15(el)
                break;
            case 'ZZTC16':
                steps = ZZTC16(el)
                break;
            case 'ZZTC17':
                steps = ZZTC17(el)
                break;
            case 'ZZTC18':
                steps = ZZTC18(el)
                break;
            case 'ZZTC19':
                steps = ZZTC19(el)
                break;
            case 'ZZTC20':
                steps = ZZTC20(el)
                break;
            case 'ZZTC21':
                steps = ZZTC21(el)
                break;
            case 'ZZTC22':
                steps = ZZTC22(el)
                break;
            case 'ZZTC23':
                steps = ZZTC23(el)
                break;
            case 'PC':
                steps = PC(el)
                break;
            case 'PC1':
                steps = PC1(el)
                break;
            case 'PC2':
                steps = PC2(el)
                break;
            case 'PC3':
                steps = PC3(el)
                break;
            case 'PC4':
                steps = PC4(el)
                break;
            case 'PC5':
                steps = PC5(el)
                break;
            case 'PC6':
                steps = PC6(el)
                break;
            case 'PC7':
                steps = PC7(el)
                break;
            case 'PC8':
                steps = PC8(el)
                break;
            case 'PC9':
                steps = PC9(el)
                break;
            case 'PC10':
                steps = PC10(el)
                break;
            case 'PC11':
                steps = PC11(el)
                break;
            case 'PC12':
                steps = PC12(el)
                break;
            case 'PC13':
                steps = PC13(el)
                break;
            case 'PC14':
                steps = PC14(el)
                break;
            case 'PC15':
                steps = PC15(el)
                break;
            case 'PC16':
                steps = PC16(el)
                break;
            case 'PC17':
                steps = PC17(el)
                break;
            case 'PC18':
                steps = PC18(el)
                break;
            case 'PC19':
                steps = PC19(el)
                break;
            case 'PC20':
                steps = PC20(el)
                break;
            case 'PC21':
                steps = PC21(el)
                break;
            case 'HFCR':
                steps = HFCR(el)
                break;
            case 'HFCR1':
                steps = HFCR1(el)
                break;
            case 'HFCR2':
                steps = HFCR2(el)
                break;
            case 'HFCR3':
                steps = HFCR3(el)
                break;
            case 'HFCR4':
                steps = HFCR4(el)
                break;
            case 'HFCR5':
                steps = HFCR5(el)
                break;
            case 'HFCR6':
                steps = HFCR6(el)
                break;
            case 'HFCR7':
                steps = HFCR7(el)
                break;
            case 'HFCR8':
                steps = HFCR8(el)
                break;
            case 'HFCR9':
                steps = HFCR9(el)
                break;
            case 'HFCR10':
                steps = HFCR10(el)
                break;
            case 'HFCR11':
                steps = HFCR11(el)
                break;
            case 'HFCR12':
                steps = HFCR12(el)
                break;
            case 'HFCR13':
                steps = HFCR13(el)
                break;
            case 'HFCR14':
                steps = HFCR14(el)
                break;
            case 'HFCR15':
                steps = HFCR15(el)
                break;
            case 'HFCR16':
                steps = HFCR16(el)
                break;
            case 'HFCR17':
                steps = HFCR17(el)
                break;
            case 'HFCR18':
                steps = HFCR18(el)
                break;
            case 'HFCR19':
                steps = HFCR19(el)
                break;
            case 'HFCR20':
                steps = HFCR20(el)
                break;
            case 'HFCR21':
                steps = HFCR21(el)
                break;
            case 'HFCR22':
                steps = HFCR22(el)
                break;
            case 'workFlowModel':
                steps = workFlowModel(el)
                break;
            case 'workFlowStep1Temp':
                steps = workFlowStep1Temp(el)
                break;
            case 'workFlowStep1Mater':
                steps = workFlowStep1Mater(el)
                break;
            case 'workFlowStep2Model':
                steps = workFlowStep2Model(el)
                break;

            case 'modelInfoSR':
                steps = modelInfoSR(el)
                break;
            case 'createGroupSR':
                steps = createGroupSR(el)
                break;

            default:
                break;
        }

        setSteps(steps)
        setCurStep(current)
        setTourOpen(guideIsShow)

        // 关闭引导时，将模型颜色恢复
        if (!guideIsShow && preStep && preStep?.blockName) {
            if (preStep?.blockType == 'xfz') {
                setColor1(preStep?.blockName, 0, 0, '#00BFFF')
            } else {
                setColor02B({ equipmentType: flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
                setColor02({ equipmentType: flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
                setColorPC({ equipmentType: flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
                setColorHFCR({ equipmentType: flowInfo.equipmentType, module: preStep?.blockName, color: 'origin' })
            }
        }
        // 延迟渲染第一个模型(业务逻辑允许：guideIsShow，全局设置允许:userInfo.guideSwitch, 有模型时：steps[current]?.blockName)
        setTimeout(() => {
            if (guideIsShow && userInfo.guideSwitch && steps[current]?.blockName) {
                if (steps[current]?.blockType == 'xfz') {
                    setColor1(steps[current]?.blockName, 0, 0, 'rgba(255,0,0)')
                } else {
                    if (page == 'ZZT2' || page == 'ZZT3' || page == 'ZZT6' || page == 'ZZT8' || page == 'ZZT10' || page == 'ZZT12' || page == 'ZZT14') {
                        setColor02B({ equipmentType: flowInfo.equipmentType, module: steps[current]?.blockName, color: '#ff9595' })
                    } else if (page == 'modelInfoSR' || page == 'ZZTC3' || page == 'ZZTC6' || page == 'ZZTC8' || page == 'ZZTC10' || page == 'ZZTC12' || page == 'ZZTC14') {
                        setColor02({ equipmentType: flowInfo.equipmentType, module: steps[current]?.blockName, color: '#ff9595' })
                    }else if(page == 'PC' || page == 'PC1' || page == 'PC4' || page == 'PC6' || page == 'PC8' || page == 'PC10' || page == 'PC12'){
                        setColorPC({ equipmentType: flowInfo.equipmentType, module: steps[current]?.blockName, color: '#ff9595' })
                    }else if(page == 'HFCR' || page == 'HFCR1' || page == 'HFCR4' || page == 'HFCR6' || page == 'HFCR9' || page == 'HFCR11' || page == 'HFCR13'){
                        setColorHFCR({ equipmentType: flowInfo.equipmentType, module: steps[current]?.blockName, color: '#ff9595' })
                    }
                }
                setPreStep(steps[current])
            }
        }, 1000);

    }, [guideIsShow])
    // 初始化 guideSwitch=0 关闭引导，1：开启引导
    if (!userInfo.guideSwitch) return null
    if (!el || el.length == 0) return null

    const closeGuide = () => {
        store.dispatch({
            type: "guideInfo",
            guideIsShow: false,
            mode: page
        });
    }

    const skipGuide = () => {
        store.dispatch({
            type: "guideInfo",
            guideIsShow: false,
        });
        skipGuideApi({ userId: parseInt(userInfo.id) }).then(res => {

            if (res.code == 0) {
                message.success('已为您关闭引导')
                localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, guideSwitch: 0 }))
            } else {
                message.error('关闭引导失败')
            }
        }
        )
    }
    return (
        <Tour
            open={ tourOpen }
            onClose={ closeGuide }
            steps={ steps }
            current={ curStep }
            onChange={ tourStepChange }
            indicatorsRender={ (current, total) => {
                return <>
                    <span>
                        第{ steps[current].step }步
                        {/* / {10} */ }
                    </span>
                    <Button size="small" style={ {} } type="link" danger onClick={ skipGuide }>结束全部引导</Button>
                </>
            } }
        />
    )
}

