import React, { lazy } from "react";

import { Navigate } from "react-router-dom";

/**
 * 通用页面
 */

// 登录
const Login = lazy(() => import("~/views/login"));

// 404
const Error404 = lazy(() => import("~/views/error/404"));
const HardwareTest = lazy(() => import("~/views/hardwareTest/hardwareTest"));

const commonRoutes = [
  // 通用路由
  {
    path: "*",
    element: <Navigate to="/404" replace />,
  },
  {
    path: "/404",
    element: <Error404 />,
  },
  {
    path: "/403",
    element: <Error404 />,
  },
  {
    path: "/405",
    element: <Error404 />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/hardwareTest",
    element: <HardwareTest />,
  },
];

export default commonRoutes;
